<template>
	<van-dialog
		v-model="visible"
		title="提示"
		:show-confirm-button="false"
    class="list-type-dialog"
	>
		<div class="list-type-dialog-close">
      <van-icon name="cross" @click="clickCancel"/>
    </div>
    <div class="dialog_input">
      <div class="dialog_input_title">PDF生成后将发送到邮箱</div>
      <input class="dialog_input__inner" v-model="userInfo.email" type="text" placeholder="请输入邮箱">
    </div>
		<div class="list-type-dialog-action">
			<van-button type="primary" block @click="clickConfirm">确 认</van-button>
		</div>
	</van-dialog>
</template>

<script>
export default {
	data: () => ({
		visible: false,
    userInfo: {},
    handleItem: {}
	}),
	methods: {
		init(item) {
      this.handleItem = item
			this.$api.user.getUserInfo().then(res => {
        if (!res.code) {
          this.userInfo = res.data || {}
          this.visible = true
        }
      })
		},
		
		clickCancel() {
			this.visible = false;
		},
		clickConfirm() {
			if (!this.userInfo.email) {
				this.$toast("请填写邮箱");
				return;
			}
      if (!this.userInfo.email.includes('@') || !this.userInfo.email.includes('.')) {
				this.$toast("请填写正确的邮箱格式");
				return;
			}

      this.$api.surveys.sendEmailForQuePDF({
        id: this.handleItem.id,
        email: this.userInfo.email
      }).then(res => {
        if (!res.code) {
          this.$toast('提交成功，请等待邮件下发')
          this.clickCancel()
        }
      })
		},
	},
};
</script>

<style lang="less" scoped>
.dialog_input {
  padding: 0 20px 14px;
  text-align: center;
  .dialog_input_title {
    padding: 6px 0 16px;
    font-family: PingFang SC;
    font-size: 15px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    color: #646A73;
  }
  &__inner {
    padding: 14px 10px;
    box-sizing: border-box;
    width: 100%;  
    display: block;
    background-color: #F5F6F7;
    outline: none;
    border: none;
    border-radius: 3px;

    font-family: PingFang SC;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
  }
}
</style>
